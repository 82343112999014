<script setup lang="ts">
import type { IconName } from './Icon.vue';

const { formatNumberToIsoEuroNumber, formatNumberToIsoNumber } = useNumbers();

// Props and emits
const props = defineProps<{
  title: string;
  type: 'progress' | 'euro' | 'info' | 'consumption' | 'power' | 'impact' | 'water-consumption' | 'water-power';
  icon?: IconName;
  data: number | { value: number; total: number } | string;
  secondaryData?: string;
  loading?: boolean;
}>();

// Computed
const getProgress = computed(() => {
  if (typeof props.data !== 'number' && typeof props.data !== 'string') {
    if (props.data.value === 0 && props.data.total === 0) return 0;
    return Math.round((props.data.value / props.data.total) * 100);
  }
  return 50;
});
</script>
<template>
  <div class="border border-gray-200 py-2 px-4 bg-white rounded-lg flex items-center">
    <div class="w-full">
      <div v-if="loading" class="mb-1">
        <ui-skeleton-loader class="h-6 w-24 rounded-xl" />
      </div>
      <p v-else class="text-base text-gray-700 mb-1">{{ title }}</p>

      <div v-if="loading">
        <ui-skeleton-loader class="h-7 w-36 rounded-2xl" />
      </div>
      <div v-else-if="type === 'progress'" class="flex items-center">
        <div class="w-full bg-gray-100 h-[14px] rounded-full">
          <div class="h-full bg-primary-500 rounded-full" :style="`width: ${getProgress}%`" />
        </div>
        <p class="text-base text-gray-700 block ml-2 whitespace-nowrap">{{ getProgress }} %</p>
      </div>

      <div v-else-if="type === 'euro' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ formatNumberToIsoEuroNumber(data) }}
      </div>
      <div v-else-if="type === 'info'" class="text-black font-semibold text-2xl">
        {{ data }}
      </div>
      <div v-else-if="type === 'consumption' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ formatNumberToIsoNumber(data) }} kWh
        <label class="text-gray-400 font-normal text-sm">{{ secondaryData }}</label>
      </div>
      <div v-else-if="type === 'power' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ formatNumberToIsoNumber(data) }} kW
      </div>
      <div v-else-if="type === 'water-consumption' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ formatNumberToIsoNumber(data) }} m<sup>3</sup>
      </div>
      <div v-else-if="type === 'water-power' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ formatNumberToIsoNumber(data) }} m<sup>3</sup>/h
      </div>
      <div v-else-if="type === 'impact' && typeof data === 'number'" class="text-black font-semibold text-xl">
        {{ data > 10000 ? `${formatNumberToIsoNumber(data / 1000)} kgCO2` : `${formatNumberToIsoNumber(data)} gCO2` }}
      </div>
      <div v-else class="text-black font-semibold text-xl">
        {{ data }}
      </div>
    </div>

    <div v-if="loading && type !== 'progress'">
      <ui-skeleton-loader class="h-12 w-12 rounded-full" />
    </div>
    <div v-else-if="type !== 'progress'">
      <div class="w-12 h-12 bg-primary-100 rounded-full flex items-center justify-center">
        <ui-icon :name="icon ? icon : 'Euro'" :stroke-width="2" class="stroke-primary-500" />
      </div>
    </div>
  </div>
</template>
